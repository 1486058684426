import React from "react"
import { graphql } from "gatsby"
import Meta from "../components/meta";
import Archive from "../components/archive";

export const Head = () => {
  return (
    <Meta title="สัญญา" />
  )
}

const ContractPage = ({ data }: any) => {
  return (
    <Archive posts={data.latest.nodes} type="contract" />
  );
}

export const pageQuery = graphql`
  query {
    latest: allStrapiContract(sort: { fields: [publishedAt], order: DESC }) {
      nodes {
        ...ContractData
      }
    }
  }
`

export default ContractPage